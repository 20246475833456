<template>
  <div :class="type">
    <shared-show :type="type" :inputs="inputs" :lefts="leftCards" :rights="rightCards" />
  </div>
</template>

<script>
import SharedShow from '@/components/shared/show.vue'

export default {
  name: 'PostDetail',
  components: {
    SharedShow
  },
  data() {
    return {
      type: 'post',
      inputs: ['name', 'description', 'content'],
      leftCards: ['groups'],
      rightCards: [
        'display',
        'category',
        'place',
        'youtube',
        'audios',
        'pictures',
        'videos'
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
